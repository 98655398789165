<script>
export default {
  name: 'SelectAutocompleteField',
  emits:['change'],
  props: {
    value: {
      type: [Number, String, Array],
    },
    options: {
      type: Array,
      default: () => []
    },
    validationName: Object,
    field: {
      type: String,
      default: 'id'
    },
    label: {
      type: String,
      default: 'name'
    },
    id: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    selectedOption() {
      if (this.multiple) {
        return this.options.filter(option => (this.value) ? this.value.includes(option[this.field]) : false);
      }
      return this.options.find(option => option[this.field] === this.value) || null;
    }
  },
  methods: {
    updateValue(option) {
      if (this.multiple) {
        this.$emit('input', option ? option.map(option => option[this.field]) : []);
      } else {
        this.$emit('input', option ? option[this.field] : null);
      }
    },
  },
};
</script>

<template>
  <v-select :options="options" :multiple="multiple" :label="label"
    :value="selectedOption" @input="updateValue" :placeholder="placeholder">
  </v-select>
</template>